<template>
  <div class="container-conteudo">
    <div class="conteudo-interno">
      <div class="nova-comunicacao-container">
        <h2>Adicionar Autorizada</h2>
          <FormularioAutorizadas :editar="false" />
      </div>
    </div>
  </div>
</template>

<script>
import FormularioAutorizadas from "@/components/elementos/paginas/autorizadas/FormularioAutorizadas.vue";

export default {
  name: "AutorizadasAdicionar",
  components: {
    FormularioAutorizadas
  }
}
</script>

<style>
</style>
